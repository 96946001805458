import   React                         , 
       { useEffect                     ,
	     useState                      ,
		 useRef                        }  from 'react';

import { useNavigate                   }  from 'react-router-dom';

import { Dispatch                      }  from 'redux';
import { useDispatch                   }  from 'react-redux';
	   
import   ReactPlayer                      from 'react-player/lazy'

import   classNames                       from 'classnames';

import   styles                           from './viewer.module.scss';

import { retrieveUserAgentInformations }  from '../../../../../network/utils';	


import { Video                         }  from '../../../../../datas/videoManagment/actionTypes';

import { useTypedSelector              }  from '../../../../../datas/useTypeSelector';

import { setCurrentlyPlayingVideo      }  from '../../../../../datas/videoManagment/actionCreators';

import { Window                        } from '../../../../widget/window/window';

import { WWW_URLS, 
         INTERNAL_LINKS                }  from '../../../../../network/urls';

export interface ViewerProps {

  viewVisible: boolean
}

export const Viewer = ({ viewVisible }: ViewerProps) => {

  const navigate  = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();

  const { catalog        } = useTypedSelector ( (state) => state.videoManagment );
  const { authentication } = useTypedSelector((state) => state.authenticationManagment );

  const urlVideo = `${WWW_URLS.Video}_000149_Lovelight_Challenge_2024_episode_10_Bande_Annonce/index.m3u8`;   ////////////////////////

  const [isVisible , setIsVisible ] = useState (false);
  const [isHovered , setIsHovered ] = useState (false);
  const [isShowing , setIsShowing ] = useState (false);
  const [isPlaying , setIsPlaying ] = useState (viewVisible);

  const [ alertWindowVisible1 , setAlertWindowVisible1 ] = useState ( false );
  const [ alertWindowVisible2 , setAlertWindowVisible2 ] = useState ( false );

  const [ infos    , setInfos     ] = useState ( retrieveUserAgentInformations () )

  let isPlaying2: boolean = viewVisible

  const playerRef = useRef<ReactPlayer>(null);

  let scrollY:number = 0;

  const handleMouseEnter = () => {

    setIsHovered(true);
  };

  const handleMouseLeave = () => {

    setIsHovered(false);
  };
  
  const handleClick = () => {
  
    const video1 = catalog.categories[2].videoSets[1].videos[9]                              ////////////
    const video2 = catalog.categories[2].videoSets[2].videos[0]

    console.log ("video1 ", video1)
    console.log ("video2 ", video2)

    let video = video1
	let subscriptionRequired = false;

    console.log ("video ", video)

    if ( typeof (video) === 'undefined' )
	 {
      console.log ("video undefined !")
	  subscriptionRequired = true;
	  video = video2
	 }

    console.log ("video ", video)
  
    if ( video.locked === false )
	      {
     	   dispatch ( setCurrentlyPlayingVideo ( video ) );

           navigate ("/video")
	      }
	 else {
	       if ( subscriptionRequired )
		         { showAlertWindow2 (); }
		    else { showAlertWindow1 (); } 
	       
	      }
	 
  }

  let timer1: ReturnType<typeof setTimeout>;
  let timer2: ReturnType<typeof setTimeout>;
 
  useEffect(() => {
  
	if ( viewVisible )
	 {
	  setIsPlaying (viewVisible) 
      isPlaying2 = viewVisible
	  
	//  if ( infos.os.name !== 'iOS' )
	 }

  }, [ isPlaying, isHovered, isPlaying, isShowing, isVisible, viewVisible]); 

  useEffect(() => {
    
    if ( window.scrollY <= 600 )
	 {
      setIsVisible (true);

      timer2 = setTimeout(() => {
      
      setIsShowing (true);
	  setIsPlaying (true)
		
		
        }, 3000);
	 }
	 
  }, []); 

  const showAlertWindow1 = () => {
  
    setAlertWindowVisible1 ( true );
  }

  const showAlertWindow2 = () => {
  
    setAlertWindowVisible2 ( true );
  }

  const closeAlertWindowWithCancelButton = () => {
  
    setAlertWindowVisible1 ( false );
    setAlertWindowVisible2 ( false );
  }

  const closeAlertWindowWithValidButton1 = () => {
  
  
    setAlertWindowVisible1 ( false );
	
    navigate ( `${INTERNAL_LINKS.Login}`)
  }

  const closeAlertWindowWithValidButton2 = () => {
  
  
    setAlertWindowVisible2 ( false );
	
	if ( authentication.token === '' )
	
       	 { navigate ( `${INTERNAL_LINKS.Subscription}`) }
	else { navigate ( `${INTERNAL_LINKS.AccountManagment}`) }
  }

  const handleEnded = () => {
  
    setIsShowing ( false )
  }

  return (
 
   
    <div className   = {classNames (styles.root )}>
   
	  {
	    alertWindowVisible1 &&
	    ( < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez être connecté pour accéder à ce programme.</span><br/><br/>Se connecter ?<br/></> }
                   messageType           = "normal"
                   onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                   onClickOnValidButton  = {closeAlertWindowWithValidButton1}
                   cancelButtonName      = "NON"
                   validButtonName       = "OUI"   /> )
	  }
			
	  {
	    alertWindowVisible2 &&
	    ( < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez être abonné standard ou premium pour accéder à ce programme.</span><br/><br/>S'abonner ?<br/></> }
                   messageType           = "normal"
                   onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                   onClickOnValidButton  = {closeAlertWindowWithValidButton2}
                   cancelButtonName      = "NON"
                   validButtonName       = "OUI"   /> )
	  }

      <div className   = {classNames(styles.contain)}>

        <div className   = {classNames(styles.left)}>
	 
	      <div className={  classNames ( styles.animated_div, ( isVisible ? styles.visible : styles.unvisible ) ) } >
        
		    <img src       = "https://lovelight.tv/images/LovelightChallenge.png" 
		         className = {classNames(styles.logo)}/>
        
		    <div className = {classNames(styles.play)}>
		    
			  {
		        isHovered 
			    ?  
			    (
		          <img src = "https://lovelight.tv/images/playHover.png" 
			           className = {classNames(styles.icon_play)} 
			           onMouseEnter = {handleMouseEnter} 
				       onMouseLeave = {handleMouseLeave}
					   onClick      = {handleClick}     />
                ) 
			    : 
			    (
		          <img src          = "https://lovelight.tv/images/play.png" 
			           className    = {classNames(styles.icon_play)}  
			           onMouseEnter = {handleMouseEnter} 
			           onMouseLeave = {handleMouseLeave}/>
                )
		      }

		      <div className   = {classNames(styles.text2,  styles.centeredText)}>Lire S. 1 Ép. 10</div>   {/*  ///////////////////////// */}

	          <img src = "https://lovelight.tv/images/wishlist.png" className = {classNames(styles.icon_wishlist)} />
 	          <img src = "https://lovelight.tv/images/info.png" className = {classNames(styles.icon_info)} />
		  		  
            </div>
      
	      </div>
	   
        </div>

        <div className   = {classNames(styles.right, styles.description_frame)}>
	 
	      {
	        ( isShowing && infos.os.name !== 'iOS' )
	        ?
	        ( 
              <div className   = {classNames(styles.preview)} >
			  
	            <ReactPlayer ref      = {playerRef}
                             url      = {urlVideo}
                             controls = {false}
                             playing  = {isPlaying2}
			                 loop     = {false}
					         onEnded  = {handleEnded}
                             width    = '100%'
                             height   = '100%' />

                {/*
                <div className   = {classNames(styles.gradient_left)}></div>
				<div className   = {classNames(styles.gradient_right)}></div>
                */}
				
              </div>						   
	        )
	        :
	        (
              <img src       = "https://media.lovelightfactory.fr/Vignettes/_000149_bande_annonce_du_lovelight_challenge_saison_1_episode_10_BIG.webp"
		           className = {classNames(styles.preview)}                                />	 
	        )
	      }

	  
	  
        </div>

      </div>

    </div>
 
  );
};

export default Viewer;

