import   React                  ,  
       { useEffect              }  from 'react';

import { useNavigate            }  from 'react-router-dom';
		 
import   classNames                from 'classnames';

import { TypewriterEffectSmooth }  from "../../ui/typewriter-effect";

import { Dispatch               }  from 'redux';
import { useDispatch            }  from 'react-redux';

import   styles                    from './account_creation_success.module.scss';

import { GlobalMenu             }  from '../../menu/global/global';
import { CookiesManager         }  from '../../cookie/manager';
import { Trigger                }  from '../../widget/trigger/trigger';
import { Footer                 }  from '../../menu/footer/footer';

import { INTERNAL_LINKS         }  from '../../../network/urls';

import { useTypedSelector       }  from '../../../datas/useTypeSelector';

import { initAuthentication     }  from '../../../datas/authenticationManagment/actionCreators';
		 


import { postEvent      }  from '../../../network/api_events_stats';

export const AccountCreationSuccess = () => {

  const   dispatch: Dispatch<any> = useDispatch();
  const   navigate                = useNavigate();
  
  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );

  useEffect(() => {

	postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

    dispatch ( initAuthentication(true) );

    window.scroll(0, 0);

  }, []); 


  const handleClick = () => {
  
    dispatch ( initAuthentication(true) );
	
	navigate (`${INTERNAL_LINKS.Login}`);
  }


  const words = [
    {
      text: "Votre",
      className: "text-green-500 dark:text-green-500",
    },
    {
      text: "compte",
      className: "text-green-500 dark:text-green-500",
    },
    {
      text: "a",
      className: "text-green-500 dark:text-green-500",
    },
    {
      text: "bien",
      className: "text-green-500 dark:text-green-500",
    },
    {
      text: "été",
      className: "text-green-500 dark:text-green-500",
    },
    {
      text: "créé.",
      className: "text-green-500 dark:text-green-500",
    },
  ];
  
  
  return (
 
    <>

      <GlobalMenu />
      <CookiesManager />

  	  <div className={classNames(styles.root)}>

    	<div className={classNames(styles.contain)}>

     	 <div className={classNames(styles.message)}>
          <TypewriterEffectSmooth words={words} />
         </div>

     	 <div className={classNames(styles.message2)}>
		   <br/>
		    Vous allez recevoir un email contenanant un lien permettant de valider votre adresse email.<br/>
           <br/>
            Nous sommes heureux de vous compter parmi nos nouveaux membres.<br/>
		   <br/>
		   <br/>
		   
		   
               <Trigger onClick         = {handleClick}
		                name            = "Page de connexion"
                        classNameNormal = {classNames(styles.button)} />
			 
		   
  	     </div>
		 
        </div>

	  </div>

    <Footer />
 
    </>
	
  );
};

export default AccountCreationSuccess;

