import   React                                  , 
       { useState                               ,
	     useEffect                              }  from 'react';

import   classNames                                from 'classnames';

import { useLocation                            }  from 'react-router-dom';

import { Dispatch                               }  from 'redux';
import { useDispatch                            }  from 'react-redux';

import   styles                                    from './shop.module.scss';

import { GlobalMenu                             }  from '../../menu/global/global';
import { Footer                                 }  from '../../menu/footer/footer';
import { TopShifter                             }  from '../../menu/top_shifter/top_shifter';
import { CookiesManager                         }  from '../../cookie/manager';

import { ProductCatalog                         }  from './blocks/catalog/product_catalog';
import { ProductSheet                           }  from './blocks/catalog/product_sheet';
import { ProductVariantChoice                   }  from './blocks/catalog/product_variant_choice';

import { setProductCatalog                      }  from '../../../datas/productManagment/actionCreators';
import { Product                                }  from '../../../datas/productManagment/actionTypes';

import { initCart                               }  from '../../../datas/cartManagment/actionCreators';
		 
import { useTypedSelector                       }  from '../../../datas/useTypeSelector';

import { getProductCatalogInfos                        ,
         ProductCatalogInfos                           }  from '../../../network/api_product';

import { postEvent                              }  from '../../../network/api_events_stats';

import { postInformationRequest                 }  from '../../../network/api_information_request';

import { WindowWithField                        }  from '../../widget/window/window_with_field';
import { WindowWithForm                         }  from '../../widget/window/window_with_form';

import { retrieveUserAgentInformations          }  from '../../../network/utils';	

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export const Shop = () => {

  const location = useLocation();
  const dispatch: Dispatch<any> = useDispatch()
  
  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  const { catalog        } = useTypedSelector ( (state) => state.productManagment );
  const { cart           } = useTypedSelector ( (state) => state.cartManagment );

  const nullProduct: Product = {
  
    id:                   0  ,
    name:                 "" ,
    catchword:            "" ,
    code:                 "" ,
    requiredStatus:       "" ,
    kind:                 "" ,   // Pour PhysicalItem, VideoMasterClass...
    description:          "" ,  
    creationDate:         "" ,
    variants:             [] ,
    characteristics:      []
  }

  const [ showSheet              , setShowSheet              ] = useState ( false );
  const [ showVariantChoice      , setShowVariantChoice      ] = useState ( false );
  const [ showRequestInformation , setShowRequestInformation ] = useState ( false );
  
  const [ currentUrbanTour       , setCurrentUrbanTour       ] = useState ( "" );
  const [ currentProduct         , setCurrentProduct         ] = useState ( nullProduct );
  const [ version                , setVersion                ] = useState ( 0 );

  var productcatalogInfos: ProductCatalogInfos = {
      code:    0 ,
	  comment: "" ,
      catalog: {
        categories: []
      }
    }; 
	
  useEffect(() => {
	
    window.scroll(0, 0);

	postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

    init ();

  }, [] );

  useEffect(() => {
	
	console.log (currentProduct)

  }, [authentication, catalog, version] );

  const init = async () => {
  
    productcatalogInfos = await getProductCatalogInfos ( );

	console.log (productcatalogInfos)

	dispatch ( setProductCatalog ( productcatalogInfos.catalog ) );
	
	if ( authentication.login !== cart.email )   ///    authentication.login !== "" && authentication.login !== cart.email
	                                             ///    authentication.login === "" && "" !== cart.email
	 {
	  dispatch ( initCart())
	 }
	
	setVersion ( version + 1 )
  }

  const handleShowSheet = (product: Product) => {
  
    setCurrentProduct (product)
    setShowSheet (true)
  }
  
  const handleShowVariantChoice = (product: Product) => {
  
    setCurrentProduct (product)
    setShowVariantChoice (true)
  }

  const handleHideSheet = () => {
  
    setCurrentProduct (nullProduct)
    setShowSheet (false)
  }

  const handleHideVariantChoice = () => {
  
    setShowVariantChoice (false)
  }

  const handleShowInformationRequest = (subject: string) => {
  
    setCurrentUrbanTour ( subject )
    setShowRequestInformation ( true );
  }

  const closeRequestInformationWindowWithCancelButton = () => {
  
    setShowRequestInformation ( false );
    setCurrentUrbanTour ( "" )
  }

  const closeRequestInformationWindowWithValidButton = ( firstname: string ,
                                                         lastname:  string ,
                                                         age:       number ,
                                                         email:     string ,
                                                         city:      string ) => {

    setShowRequestInformation ( false );

    postInformationRequest ( firstname, lastname, age, email, city, `${currentUrbanTour}` )

    setCurrentUrbanTour ( "" )
  }

  const handleUpdateShop = () => {
  
    console.log ("handleUpdateShop")
  
    setVersion ( version + 1 )
  }

  return (
            <>
		      
 		      <CookiesManager />
			  <GlobalMenu version={version}/>
              <TopShifter/>

		      {
			   showSheet &&
			   
			   ( <ProductSheet product                = {currentProduct}
			                   hideSheet              = {handleHideSheet} 
							   showRequestInformation = {handleShowInformationRequest}
							   showVariantChoice      = {handleShowVariantChoice}
							   updateShop             = {handleUpdateShop} /> )
			  }
		 
		      {
			   showVariantChoice &&
			   
			   ( <ProductVariantChoice product    = {currentProduct}
			                           hideChoice = {handleHideVariantChoice} 
									   updateShop = {handleUpdateShop}   /> )
			  }
		 
	          {
	           showRequestInformation &&
	           ( < WindowWithForm  message               = { <><span className = {classNames(styles.bold_text)}>Je souhaite recevoir par mail des information concernant l'offre "{currentUrbanTour}".</span><br/></> }
                                   messageType           = "normal"
                                   onClickOnCancelButton = {closeRequestInformationWindowWithCancelButton}
                                   onClickOnValidButton  = {closeRequestInformationWindowWithValidButton}
                                   cancelButtonName      = "NON"
                                   validButtonName       = "OUI"   
							       stylesGridInfo        = {classNames(styles.grid_info)} /> )
	          }
		 

              <div className = {classNames(styles.root)} >

                { catalog && ( < ProductCatalog showSheet         = {handleShowSheet} 
				                                showVariantChoice = {handleShowVariantChoice}  
												updateShop        = {handleUpdateShop}  /> ) }
			  

			  
			
			  
              </div>			 

			  <Footer/>

            </>
        );
};

export default Shop;
