import   React            ,  
       { useState         ,
	     useEffect        }  from 'react';

import { useNavigate      }  from 'react-router-dom';

import   classNames          from 'classnames';

import   styles              from './product_catalog.module.scss';

import { ProductCategory  }  from './product_category';

import { Product          }  from '../../../../../datas/productManagment/actionTypes';

import { ProductThumbnail }  from './product_thumbnail';


import { useTypedSelector }  from '../../../../../datas/useTypeSelector';

export interface ProductSheetProps {

  showSheet:         (product:Product) => void;
  showVariantChoice: (product:Product) => void;
  updateShop:        () => void;
}

export const ProductCatalog = ({ showSheet         ,
                                 showVariantChoice ,
								 updateShop        }: ProductSheetProps) => {

  const { catalog        } = useTypedSelector ( (state) => state.productManagment );
  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );

  const navigate  = useNavigate();
 
    useEffect(() => {

   console.log (catalog)

  }, [])

    useEffect(() => {

   console.log (catalog)

  }, [catalog,authentication])
 
  
  const thumbnails = ( numCategory: number) : JSX.Element => {

    const thumbnails = [];

    if ( catalog )
	 {
	  if ( catalog.categories )
	   {
	    if ( catalog.categories[numCategory] )
		 {
		  if ( catalog.categories[numCategory].products )
		   {
            for ( let i=0; i<catalog.categories[numCategory].products.length; i++ )
	         {
			  if ( numCategory === 2 && ( i===2 || i===3 || i===4 ))
			   {
			    continue;
			   }
			   
   	          const product = catalog.categories[numCategory].products[i];
	  
	          thumbnails.push ( <div><ProductThumbnail product           = { product } 
	                                                   showSheet         = { showSheet } 
													   showVariantChoice = { showVariantChoice } 
													   updateShop        = {updateShop}           /></div>);
	         }
		   }
		 }
	   }
	 }
	  	 
    return <>{thumbnails}</>	 
  }


   return (


<div className={classNames(styles.root)}>
      <div className={classNames(styles.offers)}>
        {catalog.categories.map((category, index) => (
          <div key={index} className={classNames(styles.grp)}>
            <div className={classNames(styles.category_title)}>
              {category.name}
            </div>
            <div className={classNames(styles.category_products, styles.line_2)}>
              {thumbnails(index)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
  
  
};

export default ProductCatalog;

