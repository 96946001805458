import   React                          ,  
       { useState                       ,
	     useEffect                      }  from 'react';

import { useNavigate                    }  from 'react-router-dom';

import { Dispatch                       }  from 'redux';
import { useDispatch                    }  from 'react-redux';

import { Rating                         }  from '@smastrom/react-rating'

import '@smastrom/react-rating/style.css'


import { LongText                       }  from '../../../../widget/long_text/long_text';

import   classNames                        from 'classnames';

import   styles                            from './product_sheet.module.scss';

import { Product                        }  from '../../../../../datas/productManagment/actionTypes';

import { initCart                       , 
         addItem                        , 
         removeItem                     , 
         changeQuantity                 }  from '../../../../../datas/cartManagment/actionCreators';

import { addItemToUserCart              ,
         removeItemFromUserCart         ,
         changeQuantityOfItemInUserCart }  from '../../../../../network/api_cart';
		 
import { useTypedSelector               }  from '../../../../../datas/useTypeSelector';

import { WWW_URLS                       , 
         INTERNAL_LINKS                 }  from '../../../../../network/urls';
 
import { postEvent                      }  from '../../../../../network/api_events_stats';

import { Window                         }  from '../../../../widget/window/window';

import { Carousel } from 'react-responsive-carousel';
import "./carousel.css"; // requires a loader

export interface ProductSheetProps {
  product: Product;
  hideSheet: () => void;
  showRequestInformation: (subject: string) => void;
  showVariantChoice: (product:Product) => void;
  updateShop: () => void;
}

export const ProductSheet = ({ product                ,
                               hideSheet              ,
							   showRequestInformation ,
							   showVariantChoice      ,
							   updateShop             }: ProductSheetProps) => {

  const navigate  = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();

  const { authentication } = useTypedSelector((state) => state.authenticationManagment );
  const { user           } = useTypedSelector((state) => state.userManagment );
  const { cart           } = useTypedSelector((state) => state.cartManagment );

  const [ alertWindowVisible , setAlertWindowVisible ] = useState ( false );

  const searchImage = (): string => {
 
    let imgFile = "";
	
	for ( let c = 0; c < product.characteristics.length; c++)
	 {
	  if ( product.characteristics[c].kind === "Photo" )
	   {
	    imgFile = product.characteristics[c].value;
		break;
	   }
	 }
	 
    if ( imgFile === "" )
     {
	  for ( let v = 0; v < product.variants.length; v++)
	   {
  	    for ( let c = 0; c < product.variants[v].characteristics.length; c++)
	     {
	      if ( product.variants[v].characteristics[c].kind === "Photo" )
	       {
	        imgFile = product.variants[v].characteristics[c].value;
		    break;
	       }
	     }
	  
        if ( imgFile !== "" )
         {
	      break;
	     }	  
	   }
     }
	 
    return imgFile
  }
  
  const searchImages = (): string [] => {
 
    let imgFiles: string [] = [];
	
	for ( let c = 0; c < product.characteristics.length; c++)
	 {
	  if ( product.characteristics[c].kind === "Photo" )
	   {
	    imgFiles.push ( `${WWW_URLS.ProductThumbnail}${product.characteristics[c].value}` );
	   }
	 }
	 
    for ( let v = 0; v < product.variants.length; v++)
	 {
  	  for ( let c = 0; c < product.variants[v].characteristics.length; c++)
	   {
	    if ( product.variants[v].characteristics[c].kind === "Photo" )
	     {
  	      imgFiles.push ( `${WWW_URLS.ProductThumbnail}${product.variants[v].characteristics[c].value}` );
         }
	   }
     }
	 
    return imgFiles
  }

  const urlImage = `${WWW_URLS.ProductThumbnail}${searchImage()}`;
  
  const urlsImage = searchImages();

  const addOneProductToCurrentCart = async () => {
  
     // Dans ce cas de figure, on suppose qu'il n'y a qu'un seul variant
	 // Si la personne est connecté, on regarde s'il y a un prix associé à son statut
	 
	 const productCode: string        = product.code;
	 const productVariantCode: string = product.variants[0].productVariantCode;
	 const priceCode: string          = user.status === "Premium" ? product.variants[0].prices[1].code : product.variants[0].prices[0].code;
	 const quantity: number           = getQuantity ( productCode , productVariantCode ) + 1;
	 
	 if ( quantity === 1 )
	       {
	        dispatch ( addItem ( productCode        ,
                                 productVariantCode ,
					             priceCode          ,
                                 quantity           ) )
								 
			if ( authentication.login !== "" )
			 {
			  await addItemToUserCart ( authentication.login ,
			                            authentication.token ,
										cart.purchaseId      ,
			                            productCode          ,
                                        productVariantCode   ,
					                    priceCode            ,
                                        quantity             )
			 }
		   }
	  else {
	        dispatch ( changeQuantity ( productCode        ,
                                        productVariantCode ,
					                    quantity           ) )
										
			if ( authentication.login !== "" )
			 {
			  await changeQuantityOfItemInUserCart ( authentication.login ,
			                                         authentication.token ,
								    		         cart.purchaseId      ,
			                                         productCode          ,
                                                     productVariantCode   ,
					                                 quantity             )
			 }
										
		
		   }
	 
	 	 setAlertWindowVisible (true)

    updateShop ()
  }

  const getQuantity = ( productCode:        string ,
	                    productVariantCode: string ): number => {
	
	let quantity: number = 0;
	
    for ( let i=0 ; i<cart.items.length; i++)
	 {
	  if ( cart.items[i].productCode === productCode && cart.items[i].productVariantCode === productVariantCode )
       {
	    quantity = cart.items[i].quantity
	   }	  
 	 }
	
	return quantity
  }

  const renderImages = (): JSX.Element => {
  
    switch ( urlsImage.length )
	 {
	  case 0 : return ( <></> )
	  
	  case 1 : return (
	  
	     <>
		   <div className = { classNames(styles.block_image)} >

		     <div className = { classNames(styles.block_image)} >
		       <img src       = { urlsImage[0] }  
			        className = { classNames(styles.image)} />
             </div>
			 
           </div>
	     </>

		)
		
	  default :  return (
	  
	     <>
		   <div className = { classNames(styles.block_image)} >

		     <Carousel showThumbs = {false} 
			            autoPlay  = {false} >
                {
				  urlsImage.map ( (url, index) => (
        
		            <div key={index}>
                       <img src={url} alt={`Photo ${index + 1}`} />
                     {/*  <p className="legend">Photo {index + 1}</p> */}
                    </div>
                  ))
				}
				
            </Carousel>
			
          </div>
	     </>

		)
	 }
  
  }

  const handleClosebutton = () => {
      
      hideSheet ();

  }

  const handleAddToCard = () => {

    const eventMessage: string = `Shop-ProductSheet-Button01:\"Ajouter au panier\":${product.name}`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    if ( product.variants.length === 1 )
          {	
           addOneProductToCurrentCart ()
          }
	 else {
           showVariantChoice ( product )
	      }
		
  }
 
  const handleInformationRequest = () => {
      
    showRequestInformation	(`${product.name}` )
  }
 

  const closeAlertWindowWithCancelButton = () => {
  
    setAlertWindowVisible ( false );
  }

  const closeAlertWindowWithValidButton = () => {
  
  
    setAlertWindowVisible ( false );
	
    navigate ( `${INTERNAL_LINKS.Cart}`)
  }


  return (
            
    <div className    = { classNames(styles.root)} >

      {
	    alertWindowVisible &&
	    ( 
		  < Window message               = { <><span className = {classNames(styles.bold_text)}>Votre produit a été ajouté à votre panier.</span><br/><br/>Voir le panier ?<br/></> }
                   messageType           = "normal"
                   onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                   onClickOnValidButton  = {closeAlertWindowWithValidButton}
                   cancelButtonName      = "NON"
                   validButtonName       = "OUI"   /> 
		)
	  }


      <button
        style={{
          position:   'absolute',
          top:        '10px',
          right:      '10px',
          zIndex:     50, // Une valeur supérieure à celle de l'image
          background: 'transparent',
          border:     'none',
          cursor:     'pointer',
		  color:      'white'
        }}
        onClick={handleClosebutton} >
        <img src="https://lovelight.tv/images/icons/close.png"
		     alt="croix" />
      </button>


				
	  <div className = { classNames(styles.grid_1)} >
			
	    <div className = { classNames(styles.grid_1_line_1)} >
		  
		  { renderImages () }
				  
        </div>
			 
		<div className = { classNames(styles.grid_1_line_2,styles.bg_gradient)} >
			 
		  <div className = { classNames(styles.grid_2)} >
			 
			<div className = { classNames(styles.grid_2_line_1)} >

              <div className = { classNames(styles.title)} >
  		        <div dangerouslySetInnerHTML={{ __html: product.name }} /> 
		      </div>

	        </div>
			
			<div className = { classNames(styles.grid_2_line_2)} >

              <div className={classNames(styles.btn)}>
           
                {
			     product.variants[0].prices.length > 0
                 ?
				 (
  		          <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
		                  onClick   = {handleAddToCard} >
			 
			        <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                    <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[#FFFF00] px-3 py-1 text-xl font-medium text-black backdrop-blur-3xl">
                       Ajouter au panier
                    </span>
          
    		      </button>
                 )
				 :
				 (
  		          <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
		                  onClick   = {handleInformationRequest} >
			 
			        <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                    <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[#FFFF00] px-3 py-1 text-xl font-medium text-black backdrop-blur-3xl">
                       Demander des informations
                    </span>
          
    		      </button>
                 )
				}
				
		      </div>

	        </div>
			   
			<div className = { classNames(styles.grid_2_line_3)} >
				
			  <div className = { classNames(styles.description)} >
			  
			    { ( product.variants[0].prices.length > 0 ) && ( <div dangerouslySetInnerHTML={{ __html: product.description }} /> ) }
				
			  </div>
			
            </div>

		  </div>
			 
		</div>
			  
      </div>
			 
    </div>
           
   );
};


export default ProductSheet;
