export enum ActionType
 {
  INIT_CATALOG                               = 'INIT_CATALOG'                               ,
  SET_CATALOG                                = 'SET_CATALOG'                                ,
  ADD_CATEGORY                               = 'ADD_CATEGORY'                               ,
  ADD_VIDEOSET                               = 'ADD_VIDEOSET'                               ,
  ADD_PRODUCT                                = 'ADD_PRODUCT'                                ,
  ADD_VIDEO                                  = 'ADD_VIDEO'                                  ,
  SET_LOCKED                                 = 'SET_LOCKED'                                 ,
  SET_CURRENTLY_PLAYING_VIDEO                = 'SET_CURRENTLY_PLAYING_VIDEO'                , 
  SET_BROADCASTING_CHANNEL_PACKAGE           = 'SET_BROADCASTING_CHANNEL_PACKAGE'           ,
  SET_CURRENTLY_PLAYING_BROADCASTING_CHANNEL = 'SET_CURRENTLY_PLAYING_BROADCASTING_CHANNEL'
 }

export interface Video
 {
  id:                   number  ,
  title:                string  ,
  shortTitle:           string  ,
  complementToTheTitle: string  ,
  description:          string  ,
  duration:             string  ,
  distribution:         string  ,
  production:           string  ,
  copyright:            string  ,
  releaseDate:          string  ,
  ranking:              number  ,
  numberOfViews:        number  ,
  imageFile:            string  ,
  videoFile:            string  ,
  visible:              boolean ,
  locked:               boolean ,
  computedDuration:     number
 }

export interface Product
 {
  id:            number ,
  name:          string ,
 }

export interface VideoSet
 {
  name:     string ,
  products: Product[]
  videos:   Video[]
 }

export interface Category
 {
  name:      string ,
  videoSets: VideoSet[]
 }

export interface Catalog
 {
  categories: Category[]
 }

export interface CurrentlyPlayingVideo 
 {
  video: Video | null;
 }

export interface BroadcastedVideo
 {
  video:          Video;
  broadcastDate : Date;
 }

export interface BroadcastingChannel
 {
  name:              string;
  broadcastedVideos: BroadcastedVideo [];
 }

export interface BroadcastingChannelPackage
 {
  channels: BroadcastingChannel [];
 }

export interface CurrentlyPlayingBroadcastingChannel
 {
  broadcastingChannel: BroadcastingChannel | null;
 }

export interface actionInitCatalog
 {
  type:                   ActionType.INIT_CATALOG;
 }

export interface actionSetCatalog
 {
  type:                   ActionType.SET_CATALOG;
  newCatalog:             Catalog;
 }

export interface actionAddCategory
 {
  type:                   ActionType.ADD_CATEGORY;
  newCategory:            Category;
 }

export interface actionAddVideoSet
 {
  type:                   ActionType.ADD_VIDEOSET;
  categoryNumber:         number;
  newVideoSet:            VideoSet;
 }

export interface actionAddProduct
 {
  type:                   ActionType.ADD_PRODUCT;
  categoryNumber:         number;
  videoSetNumber:         number;
  newProduct:             Product;
 }

export interface actionAddVideo
 {
  type:                   ActionType.ADD_VIDEO;
  categoryNumber:         number;
  videoSetNumber:         number;
  newVideo:               Video;
 }

export interface actionSetLocked
 {
  type:                   ActionType.SET_LOCKED;
  categoryNumber:         number;
  videoSetNumber:         number;
  videoNumber:            number;
  newLocked:              boolean;
 }

export interface actionSetCurrentlyPlayingVideo
 {
  type:                          ActionType.SET_CURRENTLY_PLAYING_VIDEO;
  newVideo:                      Video | null;
 }

export interface actionSetBroadcastingChannelPackage
 {
  type:                          ActionType.SET_BROADCASTING_CHANNEL_PACKAGE;
  newBroadcastingChannelPackage: BroadcastingChannelPackage;
 }

export interface actionSetCurrentlyPlayingBroadcastingChannel
 {
  type:                          ActionType.SET_CURRENTLY_PLAYING_BROADCASTING_CHANNEL;
  newBroadcastingChannel:        BroadcastingChannel | null;
 }



export type Action = actionInitCatalog                            |
                     actionSetCatalog                             |
                     actionAddCategory                            | 
					 actionAddVideoSet                            |
					 actionAddProduct                             |
                     actionAddVideo                               |
                     actionSetLocked                              |
					 actionSetCurrentlyPlayingVideo               |
					 actionSetBroadcastingChannelPackage          |
					 actionSetCurrentlyPlayingBroadcastingChannel ;
					 
export type DispatchTypeInitCatalog                            = ( args: actionInitCatalog                            ) => actionInitCatalog
export type DispatchTypeSetCatalog                             = ( args: actionSetCatalog                             ) => actionSetCatalog
export type DispatchTypeAddCategory                            = ( args: actionAddCategory                            ) => actionAddCategory
export type DispatchTypeAddVideoSet                            = ( args: actionAddVideoSet                            ) => actionAddVideoSet
export type DispatchTypeAddProduct                             = ( args: actionAddProduct                             ) => actionAddProduct
export type DispatchTypeAddVideo                               = ( args: actionAddVideo                               ) => actionAddVideo
export type DispatchTypeSetLocked                              = ( args: actionSetLocked                              ) => actionSetLocked
export type DispatchTypeSetCurrentlyPlayingVideo               = ( args: actionSetCurrentlyPlayingVideo               ) => actionSetCurrentlyPlayingVideo
export type DispatchTypeSetBroadcastingChannelPackage          = ( args: actionSetBroadcastingChannelPackage          ) => actionSetBroadcastingChannelPackage
export type DispatchTypeSetCurrentlyPlayingBroadcastingChannel = ( args: actionSetCurrentlyPlayingBroadcastingChannel ) => actionSetCurrentlyPlayingBroadcastingChannel
