import   React              ,
       { useEffect          ,
         useState           }  from 'react';

import { Link               ,
         useNavigate        }  from 'react-router-dom';

import   classNames            from 'classnames';

import   styles                from './part_02.module.scss';

import { BackgroundGradient }  from "../../../../ui/background-gradient";

import { Window             }  from '../../../../widget/window/window';

import { useTypedSelector   }  from '../../../../../datas/useTypeSelector';

import { postEvent          }  from '../../../../../network/api_events_stats';

import { INTERNAL_LINKS     }  from '../../../../../network/urls';

export const Part02 = () => {

   const navigate = useNavigate();

   const { authentication } = useTypedSelector((state) => state.authenticationManagment );

  const [ alertWindowVisible     , setAlertWindowVisible     ] = useState ( false );

   const renderText1 = (): JSX.Element => {

      return (
               <div className={classNames(styles.block_text)}>
                  <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
                     Rejoignez la communauté de Lovelight et bénéficiez de contenus premium visibles uniquement sur la plateforme.
                  </p>
                  <br/>
                  <br/>
                  <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
                     Plongez dans l'univers captivant de la création visuelle avec Lovelight, la première plateforme qui regroupe actualités, tutos,
                     reportages, émissions jeux, documentaires et rencontres avec les acteurs du monde de la photographie et de l'audiovisuel.
                     <br/>
                     Que vous soyez professionnel aguerri ou amateur éclairé, ou simple débutant, le contenu exclusif est conçu pour inspirer,
                     informer et vous transporter au cœur de l'image.
                  </p>
                  <br/>
                  <br/>
                  <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
                     Découvrez la nouvelle chaîne en continu et en direct.
                  </p>
                  <br/>
                  <br/>
                  <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
                     Sur la chaîne continue LovelightTV écoutez les voix de ceux qui racontent le monde à travers leurs objectifs. Découvrez les
                     parcours, les inspirations et les secrets de créateurs de tout horizons.
                     <br/>
                     <br/>
                     La plateforme Lovelight est plus qu'une plateforme, c'est un endroit dédié à la beauté de l'instant capturé.
		          </p>
               </div>
             )
   };

   const renderText2 = (): JSX.Element => {

      return (
               <div className={classNames(styles.block_text)}>
                  <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
                     Des rendez-vous en privé pour répondre à vos questions.
                  </p>
                  <p className="text-xl italic text-white text-neutral-600 dark:text-neutral-400">
                     Offre réservée aux abonnés Standard et Premium.
		          </p>
		          <br/>
		          <br/>
		          <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
                     Que vous soyez un débutant passionné ou un professionnel chevronné, retrouvez dans votre espace abonné l'accès au 
					 formulaire&nbsp;<span className="font-bold">Lovelight hotline</span> pour échanger avec l'équipe de Lovelight, un représentant 
					 d'une marque, ou un spécialiste d'une technique photographique. Posez vos questions et recevez une réponse dans votre 
					 espace privé (offre réservée aux abonnés Standard et Premium).
		          </p>
		          <br/>
		          <br/>
		          <br/>
                  <div className={classNames(styles.btn)}>

           <button className = "relative inline-flex justify-left h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
                   onClick = {handleSubscriptionButtonClick}>
             <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-3 py-1 text-xl font-medium text-white backdrop-blur-3xl">
               Je m'abonne
             </span>
           </button>

           </div>

               </div>
             )
   };

   const renderText3 = (): JSX.Element => {

      return (
               <div className={classNames(styles.block_text)}>
                  <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
                     Une odyssée photographique chaque mois.
		          </p>
                  <p className="text-xl italic text-white text-neutral-600 dark:text-neutral-400">
                     A partir de septembre 2024.
		          </p>
		          <br/>
		          <br/>
		          <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
                     Plongez dans l'univers captivant de la photographie à travers les yeux des maîtres qui ont
			         sculpté son histoire. Chaque mois, nous mettons en lumière un photographe légendaire,
			         un visionnaire dont l'œuvre a laissé une empreinte indélébile dans le monde de l'art visuel.<br/>
                     <br/>
                     Explorez non seulement la biographie fascinante de chaque photographe, mais également ses œuvres
	     		     les plus emblématiques, des images qui ont défini des époques, inspiré des générations et capturé
			         l'essence même de moments historiques.<br/>
                     <br/>
                     De l'objectif à la toile virtuelle, notre galerie offre une expérience immersive qui célèbre la
			         créativité, l'innovation et la passion de la photographie.<br/>
                     <br/>
                     Abonnez-vous à la plateforme Lovelight pour commencer votre exploration continue de la beauté,
			         de l'émotion et de l'art, piliers fondamentaux de la photographie.
                  </p>
			      <br/>
			      <br/>
			      <br/>
           <div className={classNames(styles.btn)}>

           <button className = "relative inline-flex justify-left h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
                   onClick = {handleSubscriptionButtonClick2}>
             <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-3 py-1 text-xl font-medium text-white backdrop-blur-3xl">
               Je m'abonne
             </span>
           </button>

           </div>

               </div>
             )
   };

   const renderText4 = (): JSX.Element => {

      return (
               <div className={classNames(styles.block_text)}>
                  <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
                     MasterClasses Urban Lovelight Tour : Visitez le Monde à Travers Votre Objectif.
                  </p>
                  <br/>
                  <br/>
		          <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
                     Envie de visiter les endroits les plus photogéniques du monde tout en perfectionnant votre art ?
                     Rejoignez-nous pour une série exclusive de masterclasses de photographie qui vous emmènera au cœur des villes
                     et des lieux incontournables.<br/>
                     <br/>
                     Chaque destination offre un cadre unique, des ruelles pavées de Rome aux terres lunaires de l'Islande,
                     en passant par un safari photo en Afrique. Nos masterclasses sont plus qu'un simple cours de
                     photographie; elles sont une immersion totale qui combine des explorations urbaines, des photos de paysages
                     ou animalières, et des sessions en studio dispensées par des photographes renommés.<br/>
                     <br/>
                     Ce que vous vivrez :<br/>
                     <ul className={classNames(styles.list)}>
                        <li>
                           Découverte et Culture : Chaque lieu est un livre ouvert, avec ses histoires et ses scènes uniques
                           à capturer. Vous serez encadrés pour réaliser les meilleures photos dans les meilleurs endroits.
                           Vous disposerez également de moments personnels pour prendre le temps de la découverte.
                        </li>
                        <li>
                           Apprentissage des techniques de photographie avec un professionnel en extérieur ou en studio
                           suivant la destination choisie.
                        </li>
                        <li>
                           Partage et Réseau : Échangez avec d'autres passionnés de photographie et bâtissez des liens durables.
                        </li>
                     </ul>
                     <br/>
                     A découvrir très prochainement, Inscrivez-vous maintenant pour recevoir le programme détaillé et
                     préparez-vous à voir le monde à travers un nouveau prisme — celui de votre appareil photo pour ceux en
                     reflex ou dans le viseur pour les autres !<br/>
                     <br/>
                     Places limitées pour une expérience exclusive. Ne manquez pas votre chance de faire partie de cette aventure
                     photographique à travers le monde !
		          </p>
		          <br/>
		          <br/>
		          <br/>

<Link to={INTERNAL_LINKS.Masterclass}>
           <div className={classNames(styles.btn)}>

	       <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
		           onClick   = {handleSubscriptionOnUrbanTour}>
             <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-3 py-1 text-xl font-medium text-white backdrop-blur-3xl">
               Voir les destinations
             </span>
           </button>
       </div>
</Link>

               </div>
             )
   };

   const renderThumbnail1 = (): JSX.Element => {

      return <>



	   <div className={classNames(styles.contain)}>
      <BackgroundGradient className="rounded-[22px] p-4 sm:p-10 bg-black dark:bg-zinc-900">

	    <div className={classNames(styles.image)}>
        <img
          src={`https://lovelight.tv/images/Lovelight-TV.png`}
          alt="Logo Lovelight-TV"
          height="400"
          width="400"
          className="object-contain"
        />
        </div>
        <p className="text-base sm:text-xl text-white mt-4 mb-2 dark:text-neutral-200 text-center">
          La chaine de la photo et de l'audiovisuel en continu
        </p>

 <div className={classNames(styles.btn)}>
           <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
		            onClick = {handleButtonClickOnStreaming} >
             <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[#FFFF00] px-3 py-1 text-xl font-medium text-black backdrop-blur-3xl">
               Voir le live
             </span>
           </button>
          </div>
		  <br/>
        <p className="text-sm text-justify text-neutral-600 text-white dark:text-neutral-400">
          Retrouvez des experts qui expliquent pour vous, appareils photo, objectifs, lumières, caméras et
		  accessoires.<br/>
		  <br/>
		  Découvrez des programmes exclusifs pour faire le choix qui transformera votre manière de capturer
		  le monde.<br/>
          <br/>
          Voyagez à travers l'objectif de photographes et plongez dans des documentaires et reportages
		  fascinants. De la magie de la nature sauvage aux tumultes des grandes métropoles, nos histoires
		  visuelles vous emmènent au-delà des images.<br/>
		  <br/>
        </p>


      </BackgroundGradient>
    </div>


           </>
   }

   const renderThumbnail2 = (): JSX.Element => {

      return <>
    	  <div className={classNames(styles.contain)}>
      <BackgroundGradient className="rounded-[22px] p-4 sm:p-10 bg-black dark:bg-zinc-900">
	    <div className={classNames(styles.image)}>
        <img
          src={`https://lovelightfactory.fr/images/HotLine-icon.png`}
          alt="Des Live thématique régulièrement<br/>avec AlexK et les marques"
          height="400"
          width="400"
          className="object-contain"
        />
        </div>
        <p className="text-base sm:text-xl text-white mt-4 mb-2 dark:text-neutral-200">
         Posez vos questions dans votre espace privé.
        </p>
         <p className="text-sm text-justify text-neutral-600 text-white dark:text-neutral-400">
           Posez vos questions à l'équipe Lovelight sur votre équipement photo ou audiovisuel
		   (abonnement standard ou premium requis).
        </p>

      </BackgroundGradient>
    </div>



           </>
  }



   const renderThumbnail3 = (): JSX.Element => {

      return <>
    	  	   <div className={classNames(styles.contain)}>
      <BackgroundGradient className="rounded-[22px] p-4 sm:p-10 bg-black dark:bg-zinc-900">
	    <div className={classNames(styles.image)}>
        <img
          src={`https://lovelightfactory.fr/images/Photo_Spot01.png`}
          alt="Les photographes iconiques"
          height="400"
          width="400"
          className="object-contain"
        />
        </div>
        <p className="text-base text-justify sm:text-xl text-white mt-4 mb-2 dark:text-neutral-200">
          Visionnaires de Lumière.
        </p>

        <p className="text-sm text-neutral-600 text-white dark:text-neutral-400">
          Une Galerie Virtuelle de la Photographie, où chaque image raconte une histoire et chaque histoire façonne notre perception du monde.
        </p>

      </BackgroundGradient>
    </div>


           </>
   }

   const renderThumbnail4 = (): JSX.Element => {

      return <>
    	  <div className={classNames(styles.contain)}>
      <BackgroundGradient className="rounded-[22px] p-4 sm:p-10 bg-black dark:bg-zinc-900">
	    <div className={classNames(styles.image)}>
        <img
          src={`https://lovelightfactory.fr/images/picto_masterclass.jpg`}
          alt="Un catalogue de masterclasses dispensées par les meilleurs"
          height="400"
          width="400"
          className="object-contain"
        />
        </div>
        <p className="text-base sm:text-xl text-white mt-4 mb-2 dark:text-neutral-200">
         Un catalogue de masterclasses dispensées par les meilleurs.
        </p>
         <p className="text-sm text-justify text-neutral-600 text-white dark:text-neutral-400">
          Que vous soyez amateur cherchant à vous améliorer ou professionnel désirant explorer de
		  nouveaux horizons, ces masterclasses sont une opportunité en or de combiner voyage, apprentissage
		  et passion pour la photographie.<br/>
          <br/>
          Prochaines étapes :Débutez l'aventure à Paris ce printemps, suivez les traces des grands
		  photographes à Florence, ou capturez l'effervescence de New York à l'automne. Chaque ville
		  vous attend avec ses secrets et ses beautés à découvrir.
        </p>

      </BackgroundGradient>
    </div>


           </>
   }


  const handleSubscriptionButtonClick = () => {

    const eventMessage: string = "Home-Part02-Button01:\"Je m'abonne\""

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

	navigate ( `${INTERNAL_LINKS.Subscription}`)
  }

  const handleSubscriptionButtonClick2 = () => {

    const eventMessage: string = "Home-Part02-Button02:\"Je m'abonne\""

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

	navigate ( `${INTERNAL_LINKS.Subscription}`)
  }
  
  const handleSubscriptionOnUrbanTour = () => {

    const eventMessage: string = "Home-Part02-Button03:\"Voir les destinations\""

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
  }

  const handleButtonClickOnStreaming = () => {

    const eventMessage: string = "Home-Part02-Button04:\"Voir le live\""

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    if ( authentication.login === "" || authentication.token === "" )
	      {
		   setAlertWindowVisible    ( true );
	      }
	 else {
           setAlertWindowVisible    ( false );

           navigate ( `${INTERNAL_LINKS.Streaming}` )
	      }
  }

  const closeAlertWindowWithCancelButton = () => {

    setAlertWindowVisible ( false );
  }

  const closeAlertWindowWithValidButton = () => {

    setAlertWindowVisible ( false );

	navigate ( `${INTERNAL_LINKS.Login}`)
  }
  
  return (

            <div className={classNames(styles.part2)}>

			 {
	           alertWindowVisible 
			   &&
	           ( 
			    < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez être connecté pour accéder au live.</span><br/><br/>Se connecter ?<br/></> }
                         messageType           = "normal"
                         onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                         onClickOnValidButton  = {closeAlertWindowWithValidButton}
                         cancelButtonName      = "NON"
                         validButtonName       = "OUI"   /> 
			   )
	         }

               <div className = { classNames ( styles.grid ) } >

                  <div className = { classNames ( styles.block_1_1 ) } > { renderThumbnail1 () } </div>
                  <div className = { classNames ( styles.block_1_2 ) } > { renderText1      () } </div>
                  <div className = { classNames ( styles.inter_1   ) } > &nbsp;                  </div>
                  <br/>
                  <div className = { classNames ( styles.block_2_1 ) } > { renderText2      () } </div>
                  <div className = { classNames ( styles.block_2_2 ) } > { renderThumbnail2 () } </div>
                  <div className = { classNames ( styles.inter_2   ) } > &nbsp;                  </div>
                  <br/>
                  <div className = { classNames ( styles.block_3_1 ) } > { renderThumbnail3 () } </div>
                  <div className = { classNames ( styles.block_3_2 ) } > { renderText3      () } </div>
                  <div className = { classNames ( styles.inter_3   ) } > &nbsp;                  </div>
                  <br/>
	              <div className = { classNames ( styles.block_4_1 ) } > { renderText4      () } </div>
                  <div className = { classNames ( styles.block_4_2 ) } > { renderThumbnail4 () } </div>
                  <div className = { classNames ( styles.inter_4   ) } > &nbsp;                  </div>

	           </div>

            </div>

          );
};

export default Part02;



