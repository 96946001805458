import React       from 'react';

import classNames  from 'classnames';

import styles      from './under_maintenance.module.scss';

import { BlocCountdown } from './bloc_countdown/bloc_countdown';

export const UnderMaintenance = () => {

 return (
 
           <div className={classNames(styles.root)}>

             <div className={classNames(styles.contain)}>
			 
               <img className = {classNames(styles.logo)} 
			        src       = "/images/Lovelight-TV.png" 
				    alt       = "logo" />

               <br/>
               <br/>
               <br/>

               
               <p className={classNames(styles.text)}>
                Service en maintenance
               </p>
               
			   {/*
			   <BlocCountdown/>
			   */}
			   
		     </div>
			 
		   </div>
 
        );
};

export default UnderMaintenance;

