import   React            ,
       { useState         ,
	     useEffect        }   from 'react';

import { useNavigate      }  from 'react-router-dom';

import   classNames          from 'classnames';
 
import   styles              from './account_creation.module.scss';

import { GlobalMenu       }  from '../../menu/global/global';
import { Footer           }  from '../../menu/footer/footer';
import { TopShifter       }  from '../../menu/top_shifter/top_shifter';
import { CookiesManager   }  from '../../cookie/manager';

import { Registration     }  from './blocs/registration/registration';
import { OfferChoice      }  from './blocs/offer_choice/offer_choice';

import { Tab2             }  from '../../widget/tab/tab2';
import { Panel            }  from '../../widget/tab/panel';

import { postEvent        }  from '../../../network/api_events_stats';

import { INTERNAL_LINKS   }  from '../../../network/urls';

export interface AccountCreationProps {

  withOfferChoice: boolean
}

export const AccountCreation = ({ withOfferChoice }: AccountCreationProps) => {

  const navigate = useNavigate();

  const [ activePanelNumber , setActivePanelNumber  ] = useState ( 0 );

  useEffect(() => {

     window.scroll(0, 0);

  }, [activePanelNumber] ); 

  useEffect(() => {

    postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , "" );
	 
	window.scroll(0, 0);
	
  }, [] );


 const handleStateOfRegistrationPanel = (state:number) => {
 
   if ( state === 1 )
    {
	 if ( withOfferChoice )
	       {
		    setActivePanelNumber (1);
		   }
	  else {
            navigate (`${INTERNAL_LINKS.AccountCreationSuccess}`);
	       }
	}
 }
  
 return (
 
   <>

    <GlobalMenu/>
 	<CookiesManager />
    <TopShifter />

    <div className={classNames(styles.root)}>
     <div className={classNames(styles.contain)}>
      <Tab2 activePanelNumber = {activePanelNumber} >
       <Panel name="Création de mon compte"><Registration getState        = { handleStateOfRegistrationPanel }
	                                                      withOfferChoice = { withOfferChoice                } /></Panel>
       <Panel name="Choix de l'offre"      ><OfferChoice                                                           /></Panel>
      </Tab2>
     </div>
    </div>

    <Footer />

   </>
 
  );
};

export default AccountCreation;

