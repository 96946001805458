import   React      from "react";
import   classNames from 'classnames';

import   styles     from './select.module.scss';

export interface FieldProps {
  choices:        string[];                                                 // Liste des choix
  value?:         string;                                                   // La valeur de l'input
  disabled?:      boolean | undefined;                                      // Modifiable ou non
  onChange?:      (event: React.ChangeEvent<HTMLSelectElement>) => void;    // Ajout d'une prop pour gérer le changement de l'input
}

export const Select = ({ choices       ,						 
                         value         , 
                         disabled      ,
                         onChange      }: FieldProps) => {

  return (
  
	        <select className   = { classNames(styles.select_input) }
				    value       = { value                           }
                    disabled    = { disabled                        }
                    onChange    = { onChange                        } >
				
				{ choices.map ( (choice) => ( 
				
				   <option className = {classNames(styles.option)} 
				           value     = {choice} >
						   
					  {choice}
					  
				   </option> 
				   
				) ) } 
      
	        </select>
   
           
         );
};

export default Select;
