import   React                                  ,    
       { useEffect                              ,
         useState                               }  from 'react';

import { Dispatch                               }  from 'redux';
import { useDispatch                            }  from 'react-redux';

import { useNavigate                            }  from 'react-router-dom';
		 
import   classNames                                from 'classnames';

import   styles                                    from './spot.module.scss';

import { BroadcastingChannelPackageInfos        ,
         getBroadcastingChannelPackageInfos     }  from '../../../../../network/api_video';

import { setBroadcastingChannelPackage          ,
         setCurrentlyPlayingBroadcastingChannel }  from '../../../../../datas/videoManagment/actionCreators';

import { INTERNAL_LINKS                         }  from '../../../../../network/urls';

import { postInformationRequest                 }  from '../../../../../network/api_information_request';

import { WindowWithField                        }  from '../../../../widget/window/window_with_field';
import { WindowWithForm                         }  from '../../../../widget/window/window_with_form';

export const Spot = () => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const [ alertWindowVisible , setAlertWindowVisible ] = useState ( false );
  const [ currentUrbanTour   , setCurrentUrbanTour   ] = useState ( "" );

  var broadcastingChannelPackageInfos: BroadcastingChannelPackageInfos = {
      code:    0 ,
	  comment: "" ,
      broadcastingChannelPackage: {
        channels: []
      }
    };

  useEffect(() => {

    	 init ();

  }, []);

  const init = async () => {

    broadcastingChannelPackageInfos = await getBroadcastingChannelPackageInfos ( false );

	dispatch ( setBroadcastingChannelPackage ( broadcastingChannelPackageInfos.broadcastingChannelPackage ) );


	dispatch ( setCurrentlyPlayingBroadcastingChannel ( broadcastingChannelPackageInfos.broadcastingChannelPackage.channels[0] ) )
  }

  const handleButtonClick = (subject: string) => {

    setCurrentUrbanTour ( subject )
    setAlertWindowVisible ( true );
  }

  const closeAlertWindowWithCancelButton = () => {
  
    setAlertWindowVisible ( false );
  }

  /*
  const closeAlertWindowWithValidButton = (email: string) => {
  
    setAlertWindowVisible ( false );
	
    postInformationRequest ( email, `${currentUrbanTour}` )
  }
  */

  const closeAlertWindowWithValidButton = ( firstname: string ,
                                            lastname:  string ,
                                            age:       number ,
                                            email:     string ,
                                            city:      string ) => {

    setAlertWindowVisible ( false );

    console.log ( "closeAlertWindowWithValidButton " , firstname, lastname, age, email, city );

    postInformationRequest ( firstname, lastname, age, email, city, `${currentUrbanTour}` )
  }

 return (
 
    <div className={classNames(styles.root)}>

	  {
	    alertWindowVisible &&
	    ( < WindowWithForm  message               = { <><span className = {classNames(styles.bold_text)}>Je souhaite recevoir par mail des information concernant l'offre "{currentUrbanTour}".</span><br/></> }
                            messageType           = "normal"
                            onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                            onClickOnValidButton  = {closeAlertWindowWithValidButton}
                            cancelButtonName      = "NON"
                            validButtonName       = "OUI"   
							stylesGridInfo        = {classNames(styles.grid_info)} /> )
	  }


      <div className={classNames(styles.part1)}>

        <div className="w-full rounded-md flex md:justify-center relative overflow-hidden">
	  
  
          <div className="p-4 max-w-9xl  mx-auto relative z-10  w-full pt-20 md:pt-20">
	  
            <h1 className="text-4xl md:text-7xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50">
              Lovelight Urban Tours.
            </h1>

		    <br/>
        
		    <p className="mt-4 font-normal text-2xl text-neutral-300 text-center mx-auto">
              Offre réservée aux membres disposant d'un abonnement annuel premium 
            </p>

            <p className="mt-4 font-normal italic text-xl text-neutral-300 text-center mx-auto">
              (abonnement annuel premium remboursé à l'achat de la première commande d'une Masterclass Urban Tour)
            </p>
					
          </div>

        </div>
	 
      </div>
	
	  <br/>

	
      <div className={classNames(styles.part2)}>

        <p className={classNames(styles.paragraph)}>
		 Les Lovelight Urban Tours offrent l'opportunité de découvrir les grandes villes ou les lieux 
		 emblématiques du monde tout en suivant une masterclass de photographie.
		</p>

        <p className={classNames(styles.paragraph)}>
         Lovelight permet aux passionnés de l’image de combiner le plaisir du voyage et de la découverte 
		 avec l’art de capturer des lieux et des instants magiques, tout en perfectionnant leurs compétences 
		 en photographie.
		</p>

        <p className={classNames(styles.paragraph)}>
         Les Lovelight Urban Tours sont des masterclasses de photographie, organisées sous forme de voyages 
		 en petits groupes et encadrées par un photographe professionnel, qui accompagne les participants 
		 dans leur apprentissage.
		</p>

        <p className={classNames(styles.paragraph)}>
         D’une durée de 3 à 12 jours, ces voyages s’adaptent à différents budgets selon les destinations. 
		 Les participants sont entièrement pris en charge par des professionnels du voyage, afin qu'ils 
		 puissent se concentrer sur la visite des lieux, la photographie et les conseils dispensés par le 
		 formateur. 
		 Du transport à l’hébergement en passant par les spots photo, tout est conçu pour profiter pleinement
         du lieu choisi et progresser en photographie. 
		 Des moments de temps libre sont également prévus pour permettre à chacun de flâner et de se reposer 
		 à sa guise.
		</p>

        <p className={classNames(styles.paragraph)}>
         Rejoignez-nous pour une expérience unique où le voyage et la photographie se rencontrent, et 
		 immortalisez vos souvenirs avec l’œil d’un professionnel !	 
		</p>

      </div>

      <br/>

      <div className={classNames(styles.part3)}>
		
  	    <div className={classNames(styles.destination_title)}>Les destinations</div>

        <div className={classNames(styles.destination_grid)}>
         <div className={classNames(styles.destination_row)}>
		  <div className={classNames(styles.destination_country, styles.grid_title)}>Pays</div>
		  <div className={classNames(styles.destination_town, styles.grid_title)}>Ville</div>
		  <div className={classNames(styles.destination_date, styles.grid_title)}>Date</div>
		  {/*<div className={classNames(styles.destination_price, styles.grid_title)}>Tarif</div>*/}
		 </div>
		 
         <div className={classNames(styles.destination_row)}>
		  <div className={classNames(styles.destination_country)}>France</div>
		  <div className={classNames(styles.destination_town)}>Nice</div>
		  <div className={classNames(styles.destination_date)}>Décembre 2024</div>
		  {/*<div className={classNames(styles.destination_price)}>A venir</div>*/}
		  <div className={classNames(styles.destination_button)}>
		   <button className = "relative inline-flex justify-left h-6 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
  		           onClick={() => handleButtonClick("Lovelight Urban Tour spécial Nice")} >
             <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-1 py-1 text-base font-medium text-white backdrop-blur-3xl">
               + d'infos
             </span>
           </button>
          </div>		  
		 </div>

         <div className={classNames(styles.destination_row)}>
		  <div className={classNames(styles.destination_country)}>Italie</div>
		  <div className={classNames(styles.destination_town)}>Rome</div>
		  <div className={classNames(styles.destination_date)}>Février 2025</div>
		  {/*<div className={classNames(styles.destination_price)}>A venir</div>*/}
		  <div className={classNames(styles.destination_button)}>
		   <button className = "relative inline-flex justify-left h-6 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
  		           onClick={() => handleButtonClick("Lovelight Urban Tour spécial Rome")} >
             <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-1 py-1 text-base font-medium text-white backdrop-blur-3xl">
               + d'infos
             </span>
           </button>
          </div>		  
		 </div>
		 
		 
         <div className={classNames(styles.destination_row)}>
		  <div className={classNames(styles.destination_country)}>Islande</div>
		  <div className={classNames(styles.destination_town)}>Road trip</div>
		  <div className={classNames(styles.destination_date)}>Mars 2025</div>
		  {/*<div className={classNames(styles.destination_price)}>A venir</div>*/}
		  <div className={classNames(styles.destination_button)}>
		   <button className = "relative inline-flex justify-left h-6 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
  		           onClick={() => handleButtonClick("Lovelight Urban Tour spécial Islande")} >
             <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-1 py-1 text-base font-medium text-white backdrop-blur-3xl">
               + d'infos
             </span>
           </button>
          </div>		  
		 </div>
         <div className={classNames(styles.destination_row)}>
		  <div className={classNames(styles.destination_country)}>Japon</div>
		  <div className={classNames(styles.destination_town)}>Tokyo</div>
		  <div className={classNames(styles.destination_date)}>Année 2025</div>
		  {/*<div className={classNames(styles.destination_price)}>A venir</div>*/}
		  <div className={classNames(styles.destination_button)}>
		   <button className = "relative inline-flex justify-left h-6 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
  		           onClick={() => handleButtonClick("Lovelight Urban Tour spécial Tokyo")} >
             <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-1 py-1 text-base font-medium text-white backdrop-blur-3xl">
               + d'infos
             </span>
           </button>
          </div>		  
		 </div>

         <div className={classNames(styles.destination_row)}>
		  <div className={classNames(styles.destination_country)}>USA</div>
		  <div className={classNames(styles.destination_town)}>New York</div>
		  <div className={classNames(styles.destination_date)}>Année 2025</div>
		  {/*<div className={classNames(styles.destination_price)}>A venir</div>*/}
		  <div className={classNames(styles.destination_button)}>
		   <button className = "relative inline-flex justify-left h-6 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
		           onClick={() => handleButtonClick("Lovelight Urban Tour spécial Etats-Unis")} >
             <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-1 py-1 text-base font-medium text-white backdrop-blur-3xl">
               + d'infos
             </span>
           </button>
          </div>		  
		 </div>
		 
         <div className={classNames(styles.destination_row)}>
		  <div className={classNames(styles.destination_country)}>Namibie</div>
		  <div className={classNames(styles.destination_town)}>Etosha</div>
		  <div className={classNames(styles.destination_date)}>Année 2025</div>
		  {/*<div className={classNames(styles.destination_price)}>A venir</div>*/}
		  <div className={classNames(styles.destination_button)}>
		   <button className = "relative inline-flex justify-left h-6 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
		           onClick={() => handleButtonClick("Lovelight Urban Tour spécial Namibie")} >
             <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-1 py-1 text-base font-medium text-white backdrop-blur-3xl">
               + d'infos
             </span>
           </button>
          </div>		  
		 </div>

         <div className={classNames(styles.destination_row)}>
		  <div className={classNames(styles.destination_country)}>Hong-Kong</div>
		  <div className={classNames(styles.destination_town)}>Hong-Kong</div>
		  <div className={classNames(styles.destination_date)}>Année 2025</div>
		  {/*<div className={classNames(styles.destination_price)}>A venir</div>*/}
		  <div className={classNames(styles.destination_button)}>
		   <button className = "relative inline-flex justify-left h-6 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
		           onClick={() => handleButtonClick("Lovelight Urban Tour spécial Hong-Kong")} >
             <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-1 py-1 text-base font-medium text-white backdrop-blur-3xl">
               + d'infos
             </span>
           </button>
          </div>		  
		 </div>
		 
		</div>

	 
      </div>

    </div>
 
  );
};

export default Spot;

