import   React   from 'react';

import   classNames     from 'classnames';

import   styles         from './video_thumbnail_black.module.scss';

export const VideoThumbnailBlack = () => {


  return (

          <>

            <div className = {classNames(styles.root)}>
		 
            </div>

          </>
           
         );
};


export default VideoThumbnailBlack;
