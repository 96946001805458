import   React            ,
       { useEffect        }  from 'react';
import   classNames         from 'classnames';
import { useLocation      }  from 'react-router-dom';
import   styles              from './home.module.scss';

import { GlobalMenu       }  from '../../menu/global/global';
import { Footer           }  from '../../menu/footer/footer';

import { TopShifter       }  from '../../menu/top_shifter/top_shifter';
import { CookiesManager   }  from '../../cookie/manager';

import { Part01           }  from './blocks/part_01/part_01';
import { Part02           }  from './blocks/part_02/part_02';
import { Part03           }  from './blocks/part_03/part_03';

import { postEvent        }  from '../../../network/api_events_stats';

import { useTypedSelector }  from '../../../datas/useTypeSelector';

export const Home = () => {

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );

  const location = useLocation();

  useEffect(() => {

    window.scroll(0, 0);

	postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

    var identifiant = location.hash.substring(1);
   
     if ( identifiant === "" )
      {   
	   identifiant = "accueil";
      }

     var element = document.getElementById( identifiant );

     if ( identifiant !== "accueil" )
           {
            if ( element !== null )
             {	 
              element.scrollIntoView (); //({ behavior: 'smooth', block: "end", inline: "nearest" })	 
  	         }
	       }
	  else {
            if ( element !== null )
             {	 
	          element.scrollTo(0, 70);
	         }
	       }
  }); 

  return (
            <>

 		      <CookiesManager />
 			  <GlobalMenu />
              <TopShifter/>

			  
              <div className={classNames(styles.root)} id="home">

              {/*
                 <section id="spot">
				  <Spot />
				 </section>
               */}

                 <section id="part01">
				  <Part01 />
				 </section>

                 <section id="part02">
				  <Part02 />
				 </section>

                 <section id="part03">
				  <Part03 />
				 </section>

              </div>			 

			<Footer/>

            </>
        );
};

export default Home;
