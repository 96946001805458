import   axios                       from 'axios';

import { getUserAgentInformations ,
         getAppInformations       }  from './utils';	

import { API_URLS                 }  from './urls';

export interface PostInformationRequestResult {
  code:    number,
  comment: string
}

export const postInformationRequest = async ( firstname: string ,
                                              lastname:  string ,
                                              age:       number ,
                                              email:     string ,
                                              city:      string ,
                                              subject:   string ): Promise<PostInformationRequestResult> => {

    console.log ( "postInformationRequest " , firstname, lastname, email, city );

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();
	
    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };
	
	var result:PostInformationRequestResult = {
      code:    0,
      comment: "",
    };
		
    try {

        const response = await axios.post (
        
                                            `${API_URLS.postInformationRequest}`,
                              
                                            { 
                                              firstname ,
                                              lastname  ,
                                              age       ,
                                              email     ,
                                              city      ,
											  subject                  											 
                                            },
                                            
                                            {
                                              headers: headers
                                            }
                                          );

        console.log ( "postInformationRequest " , response );

		if ( response.status === 201 )
		      { 
			   result = {
		         code:            1 ,
		         comment: "Demande d'information envoyée" ,
		       };
			  }
		 else {
			   result = {
		         code:     -101 ,
		         comment: `Erreur lors de la création de la demande d'information ${response.status}`,
		       };
			  }

    } 
    catch (error) {

        console.log ( "postInformationRequest " , error );

        result = {
		  code:    -201,
		  comment: "Erreur réseau",
		}; 
    }

	return result;
};
