import   React            ,
       { useState         }  from 'react';
		 
import   classNames          from 'classnames';

import   styles              from './window.module.scss';

import { WidgetFrame      }  from '../widget_frame/widget_frame';
import { WidgetFrameLabel }  from '../widget_frame/widget_frame_label';
import { Toggle           }  from '../toggle/toggle';

export interface WindowWithConfirmationProps {
  message:                 JSX.Element
  messageType?:            string
  confirmationMessage:     JSX.Element
  onClickOnCloseButton?:   (() => void) | undefined;
  onClickOnCancelButton?:  (() => void) | undefined;
  onClickOnValidButton?:   (() => void) | undefined; 
  closeButtonName?:        string;
  cancelButtonName?:       string;
  validButtonName?:        string;
  opaqueBackgroundColor?:  string;  // Nouvelle propriété
}

export const WindowWithConfirmation = ( { message               ,
                                          messageType           ,
                                          confirmationMessage   ,
                                          onClickOnCloseButton  ,
                                          onClickOnCancelButton ,
                                          onClickOnValidButton  ,
                                          closeButtonName       ,
                                          cancelButtonName      ,
                                          validButtonName       ,
                                          opaqueBackgroundColor }: WindowWithConfirmationProps) => {

  const [ errorMessage        , setErrorMessage        ] = useState ( "" );
  const [ confirmationChecked , setConfirmationChecked ] = useState ( false );

  const handleCloseButtonClick = () => {
  
    if ( confirmationChecked )
	      {
	       setErrorMessage ("")
		   
           if (typeof onClickOnCloseButton !== "undefined") 
	        {
             onClickOnCloseButton();
            }
		  }
	 else {
	       setErrorMessage ("Validation obligatoire")
	      }
  };

  const handleCancelButtonClick = () => {
  
    if (typeof onClickOnCancelButton !== "undefined") 
	 {
      onClickOnCancelButton();
     }
  };

  const handleValidButtonClick = () => {
  
    if ( confirmationChecked )
	      {
	       setErrorMessage ("")
		   
           if (typeof onClickOnValidButton !== "undefined") 
	        {
             onClickOnValidButton();
            }
		  }
	 else {
	       setErrorMessage ("Validation obligatoire")
	      }
  };

  const generateClassName = (): string => {
    
	     if ( typeof messageType === "undefined" ) { return classNames(styles.whiteMessage); } 
	else if (        messageType === "normal"    ) { return classNames(styles.whiteMessage); } 
	else if (        messageType === "alert"     ) { return classNames(styles.redMessage);   } 
	else if (        messageType === "ack"       ) { return classNames(styles.greenMessage); } 
	else                                           { return classNames(styles.whiteMessage); }
  };

  const handleConfirmationCheckedChange = ( newConfirmation: boolean ) => {
  
    setConfirmationChecked ( newConfirmation )

    if ( newConfirmation === false )
	      {
	       setErrorMessage ( "Validation obligatoire" )
          }
	 else {
	       setErrorMessage ( "" )
          }
  }

  const windowStyle = opaqueBackgroundColor ? { backgroundColor: opaqueBackgroundColor } : {};

  return (
  
    <div className = {classNames(styles.window)}
         style     = {windowStyle}    >
		 
      <div className={classNames(generateClassName(), styles.message)}>
        {message}
      </div>

      <br />

      <WidgetFrame error              = {errorMessage}
	  			   classNameGrid      = {classNames(styles.grid_confirmation)} 
				   labelBlockPosition = "right" >

         <WidgetFrameLabel className = {classNames(styles.text_conformation)}>
					 
		    {confirmationMessage}

		 </WidgetFrameLabel>

         <Toggle checked        = {confirmationChecked}
                 onChange       = {handleConfirmationCheckedChange}
		         form           = "checkbox" />
							
      </WidgetFrame>
		  
      <br />

      <div className={classNames(styles.buttons_block)}>
	  
        {
		  typeof onClickOnCloseButton !== "undefined" && 
		  (
           <button className = "mx-5 px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                   onClick   = {handleCloseButtonClick} >
             { typeof closeButtonName === "undefined" ? "Fermer" : closeButtonName }
           </button>
          )
		}

        {
		  typeof onClickOnCancelButton !== "undefined" && 
		  (
           <button className = "mx-5 px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                   onClick   = {handleCancelButtonClick} >
             { typeof cancelButtonName === "undefined" ? "Annuler" : cancelButtonName }
           </button>
          )
		}

        {
		  typeof onClickOnValidButton !== "undefined" && 
		  (
           <button className = "mx-5 px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                   onClick   = {handleValidButtonClick} >
            { typeof validButtonName === "undefined" ? "Valider" : validButtonName }
           </button>
          )
		}
      </div>
    </div>
  );
};

export default WindowWithConfirmation;
