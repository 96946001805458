import   React               ,  
       { useState            ,
	     useEffect           }  from 'react';

import { Dispatch            }  from 'redux';
import { useDispatch         }  from 'react-redux';


import   classNames             from 'classnames';

import   styles                 from './video_category.module.scss';

import { Category, 
         Video               }  from '../../../../../datas/videoManagment/actionTypes';

import { VideoThumbnail      }  from './video_thumbnail';
import { VideoThumbnailBlack }  from './video_thumbnail_black';

import { sortVideosByDate    } from '../../../../../network/api_video';

import Slider  from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export interface VideoCategoryProps {
  category: Category;
  showSheet: ( video: Video ) => void
  showZoom:  ( video:  Video  ,
               width:  number ,
			   height: number ,
			   top:    number ,
			   left:   number ) => void
  }

export const VideoCategory = ({ category  ,
                                showSheet ,
								showZoom  }: VideoCategoryProps) => {

  var videosEmpty: Video [] = [];

  const [ first  , setFirst  ] = useState(false);
  const [ videos , setVideos ] = useState(videosEmpty);
  const [ nbSlides , setNbSlides ] = useState(6);

  let settings = {
    infinite:       false,
	arrows:         true,
    speed:          500,
    slidesToShow:   nbSlides,
    slidesToScroll: nbSlides,
    initialSlide:   0
  };
  
   useEffect(() => {

    const handleResize = () => {

	       if ( window.innerWidth <  630 ) { setNbSlides (1) }
	  else if ( window.innerWidth <  950 ) { setNbSlides (2) }
	  else if ( window.innerWidth < 1250 ) { setNbSlides (3) }
	  else if ( window.innerWidth < 1550 ) { setNbSlides (4) }
	  else if ( window.innerWidth < 1870 ) { setNbSlides (5) }
	  else if ( window.innerWidth < 2100 ) { setNbSlides (6) }
	  else if ( window.innerWidth < 2350 ) { setNbSlides (7) }
	  else                                 { setNbSlides (8) }
	
/*
	       if ( window.innerWidth >= 1870 ) { setNbSlides (7) }
	  else if ( window.innerWidth >= 1800 ) { setNbSlides (6) }
	  else if ( window.innerWidth >= 1550 ) { setNbSlides (5) }
	  else if ( window.innerWidth >= 1210 ) { setNbSlides (4) }
	  else if ( window.innerWidth >=  910 ) { setNbSlides (3) }
	  else if ( window.innerWidth >=  612 ) { setNbSlides (2) }
	  else                                  { setNbSlides (1) }
*/	  
	  
	settings = {
       infinite:       false,
	   arrows:         true,
       speed:          500,
       slidesToShow:   nbSlides,
       slidesToScroll: nbSlides,
       initialSlide:   0
      };
    };

    if ( !first)
	 {
	  setFirst (true);
	  
	  handleResize();
	 }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
	
  }, [first, nbSlides]);


  
  useEffect(() => {
		   
    if ( !first )
	 {
	  init ();
      setFirst (true);
     }	  
	   
  }, [category, first, videos]); 


  const init = () => {
  
    var temp: Video [] = [];
  
    for (let i = 0; i < category.videoSets.length; i++) 
	 {
      const videoSet = category.videoSets [i];
	  
      for (let j = 0; j < videoSet.videos.length; j++) 
	   {
        const video = videoSet.videos [j];
		
		temp.push ( video );
       }
     }
	 
	temp = sortVideosByDate ( temp );  
	
    setVideos ( temp );	
  }


  const thumbnails = () : JSX.Element => {

    const thumbnails = [];

    for ( let i=0; i<videos.length; i++ )
	 {
	  const video = videos[i];
	  
	  thumbnails.push ( <div><VideoThumbnail video     = { video     } 
					                         showSheet = { showSheet }
									         showZoom  = { showZoom  } /></div>);
	 }
	  
    let numberOfMissingThumbnails = nbSlides - videos.length;
  
    if ( numberOfMissingThumbnails > 0) 
	 {
	  for (let i = 0; i < numberOfMissingThumbnails; i++) 
	   {
        thumbnails.push ( <div><VideoThumbnailBlack/></div>);
       }
     }	  
	 
    return <Slider {...settings}>{thumbnails}</Slider>	 
  }
  
  return (
            
           <div className={classNames(styles.root)}>

            <p  className={classNames(styles.title)}>
			 {category.name}
            </p>

            <div  className={classNames(styles.carousel)}>
			  
			  { thumbnails () }

            </div>
				
           </div>
           
         );
};


export default VideoCategory;

/*

				  <div>
			        <p  className={classNames(styles.title)}>
  				      {video.title}
					</p>
				  </div> 

*/
