import   axios , { AxiosError }     from 'axios';

import { getUserAgentInformations ,
         getAppInformations       }  from './utils';	   

import { API_URLS                 }  from './urls';

export interface PostResult {
  code:    number,
  comment: string
}

export const postEvent = async ( event: string ,
                                 email: string ): Promise<PostResult> => {

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();
	
    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };
	
	var result:PostResult = {
      code:    0,
      comment: "",
    };
		
    try {

        const response = await axios.post (
        
                                               `${API_URLS.postCustomerFrontEvent}`,
                              
                                               { 
                                                 event ,
												 email               											 
                                               },
                                            
                                               {
                                                 headers: headers
                                               }
                                            );

        switch ( response.status )
		 {
		  case 201 : result = {
                                code:    1 ,
		                        comment: "Evenement créé" 
		                      };
								  		   
					 break;
 
          default :  result = {
		                        code:     -102 ,
		                        comment: `Erreur lors de la création de l'événement ${response.status}`
		                      };
         }

    }  
    catch ( err ) {

        const error = err as AxiosError;

        if ( error.response && error.response.status )
	          {
               switch ( error.response.status )
		        {

                 default :  result = {
		                               code:    -201,
		                               comment: "Erreur réseau"
		                             };
                }
              }
         else {
	           result = {
		                  code:    -201,
		                  comment: "Erreur réseau"
		                };
	          }	   
    }
	
	return result;
};
